<template>
  <v-select
    class="select-category"
    :value="value"
    :items="validItems"
    :item-text="textColumn"
    :item-value="valueColumn"
    :placeholder="placeholder"
    :loading="loading || fetching"
    :disabled="disabled || fetching"
    v-bind="$attrs"
    @input="onChange"
  />
</template>
<script>
/**
 * ==================================================================================
 * Select Category
 * ==================================================================================
 **/
import { mapActions, mapMutations } from 'vuex'
import CATEGORY_TYPE from '@/utils/enums/CategoryType'

export default {
  props: {
    value: {
      type: [String, Number],
      default: null,
    },

    type: {
      type: String,
      default: CATEGORY_TYPE.general,
      validator: (value) => CATEGORY_TYPE.hasOwnProperty(value),
    },

    placeholder: {
      type: String,
      default: 'Select Category',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    valueColumn: {
      type: String,
      default: 'id',
    },

    textColumn: {
      type: String,
      default: 'label',
    },

    preSelect: {
      type: Boolean,
      default: false,
    },

    preFetch: {
      type: Boolean,
      default: true,
    },
    buildingId: {
      type: [String, Number, Array],
      default: null,
    },
  },

  created() {
    if (this.preFetch) {
      this.fetch()
    }
  },

  data() {
    return {
      fetching: false,
      categories: [],
    }
  },

  watch: {
    buildingId: {
      handler(newValue, oldValue) {
        this.fetch()
        this.$emit('input', '')
      },
    },
  },

  computed: {
    validItems() {
      return this.categories && this.categories.length ? this.categories : []
    },
  },

  methods: {
    ...mapActions({
      getCategories: 'category/getForSelects',
    }),

    ...mapMutations({
      clearCategories: 'category/clearCategoryList',
    }),

    async fetch() {
      this.fetching = true
      this.clearCategories()

      let params = {
        filter: {
          buildingId: this.buildingId,
          sort: 'position',
        },
        type: this.type,
      }

      await this.getCategories(params)
        .then((data) => {
          this.categories = data

          if (this.preSelect && this.validItems.length) {
            this.onChange(this.validItems[0].id)
          }

          this.$emit('populated', this.categories)
        })
        .finally(() => {
          this.fetching = false
        })
    },

    onChange(value) {
      this.$emit('input', value)
    },
  },
}
</script>
